@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --scrollbar-color: #7c7c7c2d;
}

/* width */
::-webkit-scrollbar {
  width: 14px; /* width of the entire scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-color); /* color of the tracking area */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #707476a5; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3.55px transparent solid;
  border-block: 4.5px transparent solid;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #8c8e8fa5; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3.55px transparent solid;
  border-block: 3.55px transparent solid;
  background-clip: padding-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
