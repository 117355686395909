/*=====================
     Loader CSS
==========================*/
.spinning {
  padding-right: 40px;
  position: relative;
}
.spinning::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  right: 10px;
  top: 4px;
  border: 2px solid rgba(255, 255, 255, 0.29);
  border-right: 3px solid white;
  animation: rotate360 0.5s infinite linear;
}
@keyframes rotate360 {
  100% {
      transform: rotate(360deg);
  }
}
.loader-wrapper, .custom-loader-wrapper {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  top: 0;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #eaeaea;
  border-bottom-color: var(--theme-color);
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
#loading-bar {
  color: var(--theme-default) !important;
}
#loading-bar .bar {
  background: var(--theme-default) !important;
}
#loading-bar-spinner {
  top: 15px !important;
  right: 0 !important;
  margin-right: 20px;
  color: var(--theme-default) !important;
}
#loading-bar-spinner .spinner-icon {
  width: 16px !important;
  height: 16px !important;
}
.skeleton-body {
  background-image: none !important;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
.skeleton-body .skeleton-text-wrap .placeholder {
  min-height: 4.3%;
  background: linear-gradient(90deg, rgba(212, 212, 212, 0.41) 8%, #cdcdcd 18%, rgba(212, 212, 212, 0.41) 33%);
  background-size: 800px 104px;
  animation: skeleton-loader 2s infinite linear;
  border-radius: 10px;
}
@keyframes skeletonShine {
  to {
      background-position: 500px 0;
  }
}
.skeleton *:empty {
  background: #f6f7f8;
  background-position: -500px 0;
  animation: skeletonShine 1s linear 0s infinite normal forwards;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
}
